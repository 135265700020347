import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Grid, Card, CardContent, Typography, Button, Alert, Box } from '@mui/material';
import './Checkout.css'; // Import the CSS file for styles

const Checkout = () => {
  const [products, setProducts] = useState([]);
  const [userDiscount, setUserDiscount] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [gstTotal, setGstTotal] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [totalDiscount, setTotalDiscount] = useState(0);
  const [grandTotal, setGrandTotal] = useState(0);
  const [userId, setUserId] = useState(1); // Replace with actual user ID if available
  const [orderSuccess, setOrderSuccess] = useState(null); // State to track order status

  useEffect(() => {
    // Retrieve cart data and user data from local storage
    const cart = JSON.parse(localStorage.getItem('cart')) || [];
    const userData = JSON.parse(localStorage.getItem('userData')) || {};
   

    // Extract discount information if user data is available
    const discount = userData.discount || 0; // Set default discount to 0 if not available
    setUserDiscount(discount);

    // Fetch product details for each cart item
    const fetchProducts = async () => {
      const productPromises = cart.map(async (item) => {
        try {
          const response = await axios.get(
            `https://namami-infotech.com/EvaraBackend/src/sku/get_sku.php?medicine_id=${item.id}`
          );
          return {
            ...response.data.medicine, // Spread the medicine object directly
            quantity: item.quantity, // Adding quantity from cart to the fetched product
          };
        } catch (error) {
          console.error('Error fetching product details:', error);
          return null;
        }
      });

      // Resolve all promises and filter out any failed requests
      const productData = (await Promise.all(productPromises)).filter((product) => product !== null);
      setProducts(productData);
    };

    fetchProducts();
  }, []);

  const calculateTotals = () => {
    let total = 0;
    let discount = 0;
    let gstAmount = 0;

    products.forEach((product) => {
      const basePrice = parseFloat(product.ptr);
      const quantity = product.quantity;

      // Handle offer conditions like "Buy X Get Y"
      let effectiveQuantity = quantity; // Start with the quantity from the cart
      let offerQuantity = 0; // Initialize offer quantity

      if (product.offer && product.offer.startsWith('Buy')) {
        const [buy, get] = product.offer.match(/\d+/g).map(Number);
        const sets = Math.floor(quantity / buy); // Calculate how many sets are being bought
        offerQuantity = sets * get; // Calculate free items
      }

      effectiveQuantity += offerQuantity; // Total quantity including free items

      // Calculate total price
      const priceWithDiscount = product.category === 'Pharma'
        ? basePrice * (1 - userDiscount / 100)
        : basePrice;

      const productTotal = priceWithDiscount * quantity;
      total += productTotal;

      // Calculate total discount for Pharma products
      if (product.category === 'Pharma') {
        discount += (basePrice * quantity) * (userDiscount / 100);
      }

      // Calculate GST
      const gst = product.gst ? (productTotal - discount) * (parseFloat(product.gst) / 100) : 0;
      gstAmount += gst;
      console.log(gst)
    });

    setTotalPrice(total);
    setTotalDiscount(discount);
    setGstTotal(gstAmount);
    setGrandTotal(total - discount + gstAmount);
  };
// console.log("total Discount ", totalDiscount)
  // Recalculate total whenever products change
  useEffect(() => {
    if (products.length > 0) {
      calculateTotals();
    }
  }, [products]);

const handlePlaceOrder = async () => {
    const cart = JSON.parse(localStorage.getItem('cart')) || [];
    const orderProducts = cart.map((item) => {
        const product = products.find(p => p.id === item.id); // Match product to get its details
        let offerQuantity = 0;
        let sets = 0;

        // Check if the product has an offer and calculate the offer quantity
        if (product && product.offer && product.offer.startsWith('Buy')) {
            const [buy, get] = product.offer.match(/\d+/g).map(Number); // Extract buy and get quantities
            sets = Math.floor(item.quantity / buy); // Calculate how many sets of the offer can be availed
            offerQuantity = sets * get; // Calculate total free items
        }

        const netQuantity = item.quantity + offerQuantity; // Total quantity (buy + free)

        // Log details for debugging
        console.log(`Product ID: ${item.id}, Buy Quantity: ${item.quantity}, Offer Quantity: ${offerQuantity}, Sets: ${sets}, Net Quantity: ${netQuantity}`);

        return {
            product_id: item.id,
            net_quantity: netQuantity, // Total quantity (buy + free)
            buy_quantity: item.quantity, // Quantity from the cart
            offer_quantity: offerQuantity, // Free items (from offer)
            sets: sets, // Include the number of free products (sets)
        };
    });

    const orderPayload = {
        user_id: userId, // Ensure you define userId from your user data
        products: orderProducts,
        total_amount: grandTotal, // Include grandTotal as total_amount
    };

    console.log('Order Payload:', orderPayload); // Log the request body to console

    try {
        const response = await axios.post('https://namami-infotech.com/EvaraBackend/src/order/order_product.php', orderPayload, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
        if (response.data.success) {
            setOrderSuccess("Order placed successfully!");
        } else {
            setOrderSuccess("Failed to place order. Please try again.");
        }
    } catch (error) {
        console.error('Error placing order:', error);
        setOrderSuccess("Error placing order. Please try again.");
    }
};


  return (
    <Box style={{ maxWidth: 1400, margin: "auto", padding: 2 }}>
      <br />
      <Typography variant="h4" gutterBottom>
        Order Summary
      </Typography>
      {orderSuccess && (
        <Alert
          severity={orderSuccess.includes("success") ? "success" : "error"}
        >
          {orderSuccess}
        </Alert>
      )}
      {userDiscount && userDiscount !== 0 && userDiscount !== "" && (
        <Alert severity="success" sx={{ maxWidth: "500px", fontSize: "15px" }}>
          {`Hurray! You unlock ${userDiscount}% discount on Pharma Products`}
        </Alert>
      )}
      <br />
      <Grid
        container
        spacing={2}
        sx={{ display: "flex", flexDirection: "row" }}
      >
        {products.map((product, index) => {
          const sets =
            product.offer && product.offer.startsWith("Buy")
              ? Math.floor(
                  product.quantity / parseInt(product.offer.split(" ")[1])
                )
              : 0;

          const offerQuantity = sets * parseInt(product.offer.split(" ")[3]); // Calculate offer quantity
          const effectiveQuantity = product.quantity + offerQuantity; // Total quantity (buy + free)

          const showOfferAlert =
            product.offer &&
            product.quantity < parseInt(product.offer.split(" ")[1]);

          return (
            <Grid item xs={12} sm={8} key={index}>
              <Card>
                <CardContent
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    <Typography variant="h6">{product.name}</Typography>
                    <Typography>Price: ₹{product.ptr}</Typography>
                    <Typography>Total Quantity: {effectiveQuantity}</Typography>
                    <Typography>
                      Offer Quantity: {offerQuantity}{" "}
                      {product.offer ? `(from offer: ${product.offer})` : ""}
                    </Typography>
                    {sets > 0 && (
                      <Alert severity="success">{`You unlock ${sets} free products.`}</Alert>
                    )}
                    <Typography>
                      Total Price: ₹
                      {(product.ptr * product.quantity).toFixed(2)}
                    </Typography>
                    {product.category === "Pharma" && (
                      <Typography>
                        Discounted Price: ₹
                        {(
                          parseFloat(product.ptr * product.quantity) *
                          (1 - userDiscount / 100)
                        ).toFixed(2)}
                      </Typography>
                    )}
                    <Typography>GST: {product.gst || "0"} %</Typography>
                  </div>
                  <div>
                    {showOfferAlert && (
                      <Alert severity="info">{product.offer}</Alert>
                    )}
                    {!showOfferAlert && product.offer && (
                      <Alert severity="success">{product.offer}</Alert>
                    )}
                    <img
                      src={product.image_url}
                      alt={product.name}
                      style={{ width: "100px", height: "100px" }}
                    />
                  </div>
                </CardContent>
              </Card>
            </Grid>
          );
        })}
      </Grid>
      <div className="total-amount-container">
        <Typography variant="h6">
          Total Price: ₹{totalPrice.toFixed(2)}
        </Typography>
        <Typography variant="h6">Total GST: ₹{gstTotal.toFixed(2)}</Typography>
        <Typography variant="h6">
          Grand Total: ₹{(totalPrice + gstTotal).toFixed(2)}
        </Typography>
        <Button
          variant="contained"
          style={{ marginTop: "10px", backgroundColor: "#32AEB1" }}
          onClick={handlePlaceOrder}
          disabled
        >
          Place Order
        </Button>
      </div>
    </Box>
  );
};

export default Checkout;
